<template>

<app-content :loading="is.loading">

	<app-content-head :title="parent.name" :tabs="tabs" :back="$route.meta.back" :readonly="$authorised('con/schedule/access', 'read')">

		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-filters>

		<app-content-filters-search v-model="filter.search" />
		<app-content-filters-date v-model="filter.from" text="From" :max="filter.to" />
		<app-content-filters-date v-model="filter.to" text="To" :min="filter.from" />

	</app-content-filters>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no messages found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column text="Time" />
				<app-table-column text="Day" />
				<app-table-column text="Badge" />
				<app-table-column text="Message" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row align="top" v-on:click="onEditClick(item.id)" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-text :text="item.date | localDate('h:mma')" />
					<app-table-cell-text :text="item.date | localDate('dddd')" />
					<app-table-cell-text :text="item.badge.name" />
					<app-table-cell-text :text="item.text" />
					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			filter: {
				from: 0,
				to: 0
			},
			layout: '80px 80px 200px auto 24px',
			gatekeep: 'convention/schedule/event',
			endpoint: 'convention/schedule/event/:event/messages',
			live: 'convention/schedule/event/:event/messages'
		}

	},

	computed: {

		tabs: function() {

			return [
				{ name: 'Convention.Schedule.Events.Edit', text: 'Setup', params: { id: this.parent.id } },
				{ name: 'Convention.Schedule.Event.Seats', text: (this.parent.ticket) ? 'Tickets' : 'Seats' },
				{ name: 'Convention.Schedule.Event.Messages', text: 'Messages' }
			]

		}

	}

}

</script>

<style scoped>

</style>